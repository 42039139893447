import { JobDetails, JobPreview, JobSimple, Paginated, WithPaginationParams } from '@borg/types';

class MyJobsService {
  async getFavoriteJobs(params: WithPaginationParams) {
    const response = await http.get<Paginated<JobSimple>>('/me/saved-jobs', { params });
    return response.data;
  }

  saveJob = (jobId: JobPreview['id'] | JobDetails['id']) =>
    serviceHttpPostFactory(`/me/saved-jobs`, { id: jobId });
  undoJobSave = (jobId: JobPreview['id'] | JobDetails['id']) =>
    serviceHttpDeleteFactory(`/me/saved-jobs/${jobId}`);
}

export const myJobsService = new MyJobsService();
