import { isPromise, isBoolean } from '@borg/utils';

type SuccessAction = (...args: unknown[]) => void;
type FailAction = (...args: unknown[]) => Promise<boolean | void> | boolean | void;

export function ifAuth(success: SuccessAction, fail?: FailAction, redirect = true) {
  const userStore = useUserStore();
  if (userStore.isAuthenticated) {
    success();
  } else if (fail) {
    const result = fail();
    if (isPromise(result)) {
      result.then((res) => res && success());
    } else if (isBoolean(result)) {
      if (result) {
        success();
      }
    }
  } else if (redirect) {
    navigate({
      name: 'auth-login',
      query: { redirect: useRoute().fullPath, view: 'login' },
    });
  }
}
